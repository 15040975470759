<template>
  <div
    class="shop-height shop-page"
    :class="{
      'guest-user': isGuest,
      'brand-user': isBrand,
      'retailer-user': isRetailer,
    }"
  >
    <div
      v-for="(groupedProduct, index) in productsWithWishlists"
      :key="index"
    >
      <div
        v-if="groupedProduct._id && groupedProduct.products.length"
        class="group-heading"
        :class="{ 'pt-2': !!wishlists.length }"
        :style="`z-index :${index + 2}`"
      >
        <div class="name">
          {{ groupedProduct._id }}
        </div>
      </div>
      <section
        ref="cardColumns"
        :class="itemViewClass"
        class="card-columns shop-items"
      >
        <b-card
          v-for="(product, key) in groupedProduct.products"
          :key="`${product._id}-${key}`"
          ref="product_card_ref"
          class="ecommerce-card ecom overflow-visible"
          :class="{
            'no-hover': isWishlistDropdownOpened || isNoHoverProductCard,
          }"
          no-body
        >
          <span
            v-if="product.keyStyles"
            class="text-capitalize table-status key-style"
          >
            {{ product.keyStyles }}
          </span>
          <b-form-checkbox
            v-if="isCheckBoxVisible"
            :id="product._id"
            :checked="indexes.includes(product._id)"
            class="customCheck"
            :class="showCheckBox ? 'showCheckAll' : ''"
            @change="onCheckboxChange(product, $event)"
          />
          <span
            v-if="PRODUCT_STATUS.CANCELLED.VALUE === product.status"
            class="product-status-tag cancelled"
          >{{ PRODUCT_STATUS.CANCELLED.LABEL }}</span>
          <div class="item-img text-center p-0 pb-0 position-realtive">
            <b-link
              :class="showCheckBox ? 'pointerevents' : ''"
              target="_blank"
              @click="openProductDetail(product)"
            >
              <div class="card-square-image d-block">
                <b-card-img-lazy
                  class="bg-gray img img-responsive fullwidth"
                  :alt="`${product.name}-${product._id}`"
                  fluid-grow
                  :src="useEcommerceUi().parseProductImage(product)"
                  :class="`img-${product._id}`"
                  @load.native="calculateImageRatio(`img-${product._id}`)"
                  @error.native="imageLoadError"
                />
                <div
                  v-if="product.cartQuantity"
                  class="items-count-wrapper"
                >
                  <div class="icon-wrapper">
                    <shopping-cart-icon class="icon" />
                  </div>

                  <span class="items-count">
                    Qty ({{ product.cartQuantity }})</span>
                </div>
                <div class="product-color-wrapper">
                  <div
                    v-for="productColors in product.colors"
                    :key="productColors.color"
                    class="product-color-bg"
                  >
                    <div
                      v-if="getColorCode(productColors.colorFamily)"
                      v-b-tooltip.hover.left="productColors.color"
                      class="product-color"
                      :class="classForWhiteColor(productColors.colorFamily)"
                      :style="
                        'background:' + getColorCode(productColors.colorFamily)
                      "
                    />
                  </div>
                </div>
              </div>
              <!-- Product Details -->

              <b-card-body class="p-0">
                <div class="card-body-padding">
                  <div class="product-name item-name d-flex">
                    <b-link
                      :id="`tooltip_product_name_${product._id}`"
                      :class="showCheckBox ? 'pointerevents' : ''"
                      class="text-left"
                      @click="openProductDetail(product)"
                      @mouseover="
                        showTooltip(
                          `tooltip_product_name_${product._id}`,
                          product._id
                        )
                      "
                    >
                      {{ product.name }}
                    </b-link>
                    <b-tooltip
                      v-if="product._id === tooltipId"
                      class="font-Inter"
                      :target="`tooltip_product_name_${product._id}`"
                      placement="bottom"
                      triggers="hover"
                    >
                      {{ product.name }}
                    </b-tooltip>
                  </div>
                  <div class="style-code-margin">
                    <b-card-text class="product-style-code">
                      <span class="style-code"> Style Code: </span>
                      {{ product.styleCode }}
                    </b-card-text>
                  </div>
                  <div
                    class="d-flex flex-wrap"
                    :class="
                      isGuest
                        ? 'justify-content-end'
                        : 'justify-content-between'
                    "
                  >
                    <b-card-text
                      v-if="!isGuest && isShowWsp"
                      class="
                        text-left
                        product-price
                        text-nowrap
                        d-flex
                        flex-wrap
                      "
                    >
                      WSP:
                      <!-- To replace red strike line remove the condition -->
                      <span
                        v-if="!product.discountedPrice"
                        :class="{
                          'text-line-through': product.discountedPrice,
                        }"
                      >
                        {{ formatCurrency(product.price, product.currency) }}
                      </span>
                      <span
                        v-if="product.discountedPrice"
                        class="discounted-price"
                      >
                        {{
                          formatCurrency(
                            product.discountedPrice,
                            product.currency
                          )
                        }}
                      </span>
                    </b-card-text>
                    <p class="mb-0 product-price-rrp">
                      RRP:
                      {{
                        formatCurrency(product.retailPrice, product.currency)
                      }}
                    </p>
                  </div>
                </div>
              </b-card-body>
            </b-link>
          </div>
          <!-- Product Actions -->
          <div
            :id="`tooltip_add_to_cart_btn_${product._id}`"
            class="item-options text-center shop-card-options"
          >
            <b-button
              v-if="isGuest"
              v-b-tooltip.hover.bottom.v-primary
              tag="a"
              class="btn-cart p-0 intro-add-cart h-auto button-cart"
              title="You should sign up to add the product in your cart."
              @click="onClickGuestSignUp"
            >
              <feather-icon
                icon="ShoppingCartIcon"
                class="mr-50"
                size="18"
              />
              <span class="large-view-cart-text">Add to Cart</span>
            </b-button>
            <b-button
              v-if="isRetailer && isShowWsp"
              variant="none"
              tag="a"
              :class="[
                'btn-cart p-0 intro-add-cart button-cart',
                { 'added-to-cart': product.isInCart },
                { pointerevents: showCheckBox },
              ]"
              :disabled="inactiveCollection || isBrandNotAccessible"
              @click="reactHandleCartActionClick(product, key, index)"
            >
              <feather-icon
                :icon="product.isInCart ? 'EyeIcon' : 'ShoppingCartIcon'"
                class="mr-50"
                size="18"
                :class="product.isInCart && 'cart-icon'"
              />
              <span class="compact-view-cart-text">{{
                product.isInCart ? 'In Cart' : 'Add'
              }}</span>
              <span class="large-view-cart-text">{{
                product.isInCart ? 'View In Cart' : 'Add to Cart'
              }}</span>
            </b-button>
            <TooltipAddToCartBtn
              v-if="isRetailer && isBrandNotAccessible && isShowWsp"
              :target-id="`tooltip_add_to_cart_btn_${product._id}`"
            />
            <b-button
              v-if="isRetailer && !isShowWsp"
              tag="a"
              :variant="connectionRequestStatus ? '' : 'primary'"
              class="btn-cart p-0 intro-add-cart button-cart"
              :disabled="!!connectionRequestStatus"
              @click="
                !connectionRequestStatus && $bvModal.show('connection_request')
              "
            >
              <span class="text-white">{{
                getRequestBtnText(connectionRequestStatus)
              }}</span>
            </b-button>
            <!-- Wishlist dropdown -->
            <product-wishlists-dropdown
              v-if="isShowWsp"
              :product="product"
              :wishlists="product.wishlistsToShow"
              :show-check-box="showCheckBox"
              :disabled="isGuest || inactiveCollection || isBrandNotAccessible"
              :card-width="cardWidth"
              :is-opened-dropdown="isWishlistDropdownOpened"
              :is-selections="isBrand"
              :product-wishlists-count="product.wishlistsCount"
              @select-wishlist-on-product="
                onSelectWishlistOnProduct(product, key, index, $event)
              "
              @create-wishlist="createWishlist(product, index)"
              @toggle-wishlist-dropdown="
                onToggleWishlistDropdown(product, $event)
              "
            />
          </div>
        </b-card>
      </section>
    </div>
    <wishlist-list
      :wishlists="wishlists"
      :selected-wishlist="selectedWishlist"
      :is-disable-create-wishlist="isDisableCreateWishlistBtn"
      @move-to-wishlist="moveAllToWishList"
    />
    <b-modal
      :id="modalId"
      size="xl"
      hide-footer
      modal-class="brand-cart-modal"
      title="Add to Order"
      @close="onCloseAddToCartModal"
    >
      <brand-cart
        :cart-products="cartProducts"
        :retailer-id="retailerId"
        :is-loading-cart="isLoadingCart"
        :get-cart-products-by-brand="getCartProductsByBrand"
        :is-brand-cart-updated="isBrandCartUpdated"
        :modal-id="modalId"
        @close-brand-cart-modal="onCloseAddToCartModal"
        @get-full-selected-products="getFullSelectedProducts"
      />
    </b-modal>
  </div>
</template>
<script>
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { isEllipsisActive } from '@/@core/utils/utils'
import constants, {
  DOWNLOAD_TYPES,
  FILE_FORMATS,
  wishlistAction,
} from '@/constants'
import { classForWhiteColor, getColorCode } from '@/product-colors'
import {
  CLEAR_CART,
  FETCH_CART_PRODUCTS,
  GET_PRODUCTS_BY_IDS,
  SET_IS_NO_HOVER_PRODUCT_CARD,
} from '@/store/modules/shop.module'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
  BButton,
  BCard,
  BCardBody,
  BCardImgLazy,
  BCardText,
  BFormCheckbox,
  BLink,
  BModal,
  BTooltip,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import { DOWNLOAD_COLLECTION } from '@/store/modules/collection.module'
import {
  productDetailsRouteName,
  productDetailsWithWishlistRouteName,
} from '@/router/routes/apps'
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'
import {
  SET_IS_ENABLE_CREATE_WISHLIST,
  SET_IS_ENABLE_REMOVE_WISHLIST_BUTTON,
} from '@/store/modules/wishlist.module'
import ProductWishlistsDropdown from '@/views/components/product-wishlists-dropdown/ProductWishlistsDropdown.vue'
import ShoppingCartIcon from '@/@core/assets/svg-components/ShoppingCart.vue'
import UserRoleMixin from '../UserRoleMixin.vue'
import BrandCart from '../checkout/BrandCart.vue'
import { useEcommerceUi } from '../useEcommerce'
import WishlistList from '../wishlist/WishlistList.vue'
import TooltipAddToCartBtn from './TooltipAddToCartBtn.vue'

const { formatCurrency } = utils

const { PRODUCT_STATUS } = c

const { parseProductImage } = useEcommerceUi
const { TRACKS, CONNECTION_REQUEST_STATUS } = constants
const { MULTI_SELECT_PRODUCTS } = TRACKS.ACTIONS

export default {
  components: {
    BCard,
    BButton,
    BTooltip,
    BLink,
    BFormCheckbox,
    BCardText,
    BCardBody,
    BCardImgLazy,
    TooltipAddToCartBtn,
    WishlistList,
    BrandCart,
    BModal,
    ProductWishlistsDropdown,
    ShoppingCartIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixin, ProductImageMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    wishlists: {
      type: Array,
      default: () => [],
    },
    selectedWishlist: {
      type: Object,
      default: () => {},
    },
    inactiveCollection: {
      type: Boolean,
      default: false,
    },
    isShowWsp: {
      type: Boolean,
      default: false,
    },
    connectionRequestStatus: {
      type: String,
      default: '',
    },
    isBrandNotAccessible: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      type: String,
      default: '',
    },
    reactHandleCartActionClick: {
      type: Function,
      default: () => {},
    },
    itemViewClass: {
      type: String,
      default: '',
    },
    reactToggleProductInWishlist: {
      type: Function,
      default: () => {},
    },
    hasDirectRetailers: {
      type: Boolean,
      default: false,
    },
    addMultipleItemsCartActionClick: {
      type: Function,
      default: () => {},
    },
    isCheckBoxDisabled: {
      type: Boolean,
      default: false,
    },
    isDisableCreateWishlist: {
      type: Boolean,
      default: false,
    },
    directRetailerId: {
      type: String,
      default: '',
    },
    collectionProductsCount: {
      type: Number,
      default: 0,
    },
    indexes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formatCurrency,
      parseProductImage,
      useEcommerceUi,
      getColorCode,
      classForWhiteColor,
      tooltipId: '',
      selected: null,
      groupIndexes: [],
      cartProducts: [],
      retailerId: null,
      PRODUCT_STATUS,
      isBrandCartUpdated: 1,
      isDisableCreateWishlistBtn: false,
      deselectedProducts: [],
      isLoadingCart: false,
      wishlistDropdownOpenedCount: 0,
      cardWidth: 0,
      updatedProductsIdsSet: new Set(),
      productList: [],
      modalId: 'brand-add-to-cart-modal',
    }
  },
  computed: {
    ...mapState({
      profileData: state => state.auth.profileData,
      isSelectedAllProducts: state => state.shop.isSelectedAllProducts,
      selectCount: state => state.shop.selectCount,
      isNoHoverProductCard: state => state.shop.isNoHoverProductCard,
    }),
    isWishlistDropdownOpened() {
      return !!this.wishlistDropdownOpenedCount
    },
    isCheckBoxVisible() {
      return (
        !this.isCheckBoxDisabled
        && this.isShowWsp
        && ((this.isRetailer && !this.inactiveCollection) || this.isBrand)
        && !this.isBrandNotAccessible
      )
    },
    showCheckBox() {
      return this.indexes.length > 0
    },
    productsWithWishlists() {
      return this.products.map(groupedProduct => ({
        ...groupedProduct,
        products: groupedProduct.products.map(product => ({
          ...product,
          wishlistsToShow: this.getWishlistsToShow(product),
          wishlistsCount: this.getProductWishlistsCount(product),
        })),
      }))
    },
  },
  watch: {
    isDisableCreateWishlist(val) {
      this.isDisableCreateWishlistBtn = val
    },
    indexes(val) {
      this.groupIndexes = this.products.flatMap((group, groupIndex) => group.products
          .filter(product => val.includes(product._id))
          .map(product => ({
            groupIndex,
            productId: product._id,
          })))
      this.productList = this.products.flatMap(groupIndex => groupIndex.products.filter(product => val.includes(product._id)))
    },
  },
  created() {
    this.$root.$refs.shop = this
    if (this.isRetailer) {
      this.retailerId = this.profileData?._id
    }
  },
  mounted() {
    setTimeout(() => {
      this.setCardWidth()
    }, 500)
    window.addEventListener('resize', this.setCardWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.setCardWidth)
    this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, false)
    this.$store.commit(SET_IS_ENABLE_REMOVE_WISHLIST_BUTTON, false)
  },
  emits: [
    'on-deselect-products',
    'update-products-by-wishlist',
    'on-move-all-to-wishlist',
    'force-toggle-select',
  ],
  methods: {
    async getFullSelectedProducts() {
      this.isLoadingCart = true
      try {
        const res = await this.fetchProductData()
        this.updateSelectedProducts(res?.data?.data)
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isLoadingCart = false
      }
    },

    fetchProductData() {
      return this.$store.dispatch(GET_PRODUCTS_BY_IDS, {
        payload: {
          productIds: this.indexes,
          retailerId: this.retailerId,
        },
      })
    },

    updateSelectedProducts(data) {
      if (Array.isArray(data) && data.length > 0) {
        this.cartProducts = data
      }
    },

    handleError(error) {
      console.error('Error fetching products:', error)
    },

    onCheckboxChange(product, isChecked) {
      const productId = product._id
      let newIndexes

      if (isChecked) {
        if (!this.indexes.includes(productId)) {
          newIndexes = [...this.indexes, productId]
          analytics.track(
            MULTI_SELECT_PRODUCTS.USER_SELECTS_PRODUCT_BY_CHECKBOX_IN_COLLECTION_PRODUCTS_PAGE,
            {
              product,
            },
          )
        } else {
          return
        }
      } else {
        newIndexes = this.indexes.filter(id => id !== productId)
        analytics.track(
          MULTI_SELECT_PRODUCTS.USER_DESELECTS_PRODUCT_BY_CHECKBOX_IN_COLLECTION_PRODUCTS_PAGE,
          {
            product,
          },
        )
      }

      this.$emit('update:indexes', newIndexes)
      this.handleRemoveWishlistButton()
    },
    setCardWidth() {
      this.cardWidth = this.$refs?.product_card_ref?.[0]?.offsetWidth || 0
    },
    onToggleWishlistDropdown(product, isOpened = false) {
      this.wishlistDropdownOpenedCount += isOpened ? 1 : -1
      product.isOpenedDropdown = isOpened
    },
    onSelectWishlistOnProduct(product, key, index, wishlist) {
      let action = this.isRetailer
        ? wishlistAction.ADD_TO_WISHLIST
        : wishlistAction.ADD_BY_BRAND
      if (product.wishlists.includes(wishlist._id)) {
        action = wishlistAction.REMOVE_FROM_WISHLIST
      }
      this.reactToggleProductInWishlist(
        product,
        key,
        index,
        action,
        wishlist._id,
        wishlist.name,
        wishlist,
      )
    },
    openProductDetail(product) {
      let name = productDetailsRouteName
      const params = { id: product._id, collectionId: this.collectionId }
      const query = {}
      if (this.directRetailerId) {
        query.retailerId = this.directRetailerId
      }
      if (this.selectedWishlist?._id) {
        name = productDetailsWithWishlistRouteName
        params.wishlistId = this.selectedWishlist._id
      }
      const routeData = this.$router.resolve({
        name,
        params,
        query,
      })
      window.open(routeData.href, '_blank')
    },
    getRequestBtnText(requestStatus) {
      if (requestStatus) {
        return requestStatus === CONNECTION_REQUEST_STATUS.DECLINED
          ? 'Request declined'
          : 'Request sent'
      }
      return 'Request WS price'
    },
    showTooltip(id, productId) {
      const productNameElement = document.getElementById(id)
      if (productNameElement && isEllipsisActive(productNameElement)) {
        this.tooltipId = productId
      }
    },
    async addToOrder() {
      await this.getFullSelectedProducts()
      this.unSelectAll()
      analytics.track(TRACKS.ACTIONS.BRAND_CLICKS_ON_ADD_TO_ORDER, {
        product_count: this.cartProducts.length,
        product_ids: this.cartProducts.map(product => product._id),
      })
      this.$bvModal.show('brand-add-to-cart-modal')
    },
    onCloseAddToCartModal() {
      this.cartProducts = []
      this.$bvModal.hide('brand-add-to-cart-modal')
    },
    handleRemoveWishlistButton() {
      this.$nextTick(() => {
        const isProductInWishlist = this.productList.some(product => product?.wishlists.includes(this.selectedWishlist?._id))
        this.$store.commit(
          SET_IS_ENABLE_REMOVE_WISHLIST_BUTTON,
          isProductInWishlist,
        )
      })
    },
    // Used when using import cart by brand
    async getCartProductsByBrand(retailerId) {
      this.isLoadingCart = true
      try {
        const params = {}
        if (this.isBrand) {
          params.retailerId = retailerId
        }
        const res = await this.$store.dispatch(FETCH_CART_PRODUCTS, params)
        this.cartProducts = res.data.data.products
        this.isBrandCartUpdated += 1
        setTimeout(async () => {
          const payload = {
            retailerId,
          }
          await this.$store.dispatch(CLEAR_CART, { payload })
        }, 1000)
        this.isLoadingCart = false
      } catch (e) {
        apiToastWarning('Failed to import cart')
        this.isLoadingCart = false
      }
    },
    onClickGuestSignUp() {
      this.$root.$refs.guestSignup.openModal()
    },
    selectOrCreateWishlist() {
      this.isDisableCreateWishlistBtn = false
      this.$bvModal.show('wishlist-list')
    },
    createWishlist(product, index) {
      this.productList.push(product)
      const groupIndexes = {
        groupIndex: index,
        productId: product._id,
      }
      this.groupIndexes.push(groupIndexes)
      this.$store.commit(SET_IS_ENABLE_CREATE_WISHLIST, true)
      this.$bvModal.show('wishlist-list')
    },
    getFilteredWishlists(product) {
      return product.wishlists
        .map(id => this.wishlists.find(wishlist => wishlist._id === id))
        .filter(Boolean)
    },
    getWishlistsToShow() {
      if (this.directRetailerId) {
        const wishlistsToShow = this.wishlists.filter(
          wishlist => this.directRetailerId === wishlist.userId,
        )

        return wishlistsToShow
      } else {
        return this.wishlists
      }
    },
    getProductWishlistsCount(product) {
      if (this.directRetailerId) {
        const wishlistsToShow = this.getFilteredWishlists(product)
        return wishlistsToShow.length || 0
      } else {
        return product.wishlists ? product.wishlists.length : 0
      }
    },
    onToggleHeartIcon(product, key, index) {
      if (product.isInWishlist) {
        this.reactToggleProductInWishlist(
          product,
          key,
          index,
          wishlistAction.REMOVE_FROM_WISHLIST,
        )
        return
      }
      if (this.wishlists.length) {
        this.reactToggleProductInWishlist(product, key, index)
      } else {
        this.createWishlist(product, index)
      }
    },
    moveAllToWishList(data = {}) {
      const {
 wishlistName, isCreateWishlist, wishlistId, wishlistData,
} = this.extractWishlistData(data)
      if (!this.isDisableCreateWishlistBtn) {
        if (this.isSelectedAllProducts) {
          this.handleSelectAllMoveToWishlist(
            wishlistId,
            wishlistName,
            isCreateWishlist,
          )
          return
        }
        this.handleMoveToWishlist(
          wishlistId,
          wishlistName,
          wishlistData,
          isCreateWishlist,
        )
      }
      this.unSelectAll()
      if (this.isRetailer) {
        this.$emit('update-products-by-wishlist', { wishlistId })
      }
    },
    extractWishlistData(data) {
      const wishlistName = data?.wishlistName || ''
      const isCreateWishlist = !!data?.isCreateWishlist
      let wishlistId = null
      let wishlistData = null
      if (!isCreateWishlist) {
        wishlistId = data?.wishlistId
        wishlistData = data?.wishlist
      }
      return {
        wishlistName,
        isCreateWishlist,
        wishlistId,
        wishlistData,
      }
    },
    handleSelectAllMoveToWishlist(wishlistId, wishlistName, isCreateWishlist) {
      this.$emit('on-select-all-move-to-wishlist', {
        wishlistId,
        wishlistName,
        isCreateWishlist,
      })
      this.forceToggleSelect(false)
    },
    handleMoveToWishlist(
      wishlistId,
      wishlistName,
      wishlistData,
      isCreateWishlist,
    ) {
      const action = this.isRetailer
        ? wishlistAction.ADD_TO_WISHLIST
        : wishlistAction.ADD_BY_BRAND
      if (this.productList.length) {
        this.reactToggleProductInWishlist(
          this.productList,
          this.indexes,
          this.groupIndexes,
          action,
          wishlistId,
          wishlistName,
          wishlistData,
          isCreateWishlist,
        )
      }
    },
    removeAllFromWishList() {
      const action = wishlistAction.REMOVE_FROM_WISHLIST
      this.reactToggleProductInWishlist(
        this.productList,
        this.indexes,
        this.groupIndexes,
        action,
        null,
        this.selectedWishlist?.name,
      )
      this.unSelectAll()
    },
    moveAllToCart() {
      this.addMultipleItemsCartActionClick({
        product: this.productList,
        selectedProductsIds: this.indexes,
        groupIndexes: this.groupIndexes,
      })
      this.unSelectAll()
    },
    unSelectAll() {
      this.forceToggleSelect(false)
    },
    async downloadSelectedProducts() {
      try {
        const payload = {
          downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
          respectiveId: this.collectionId,
          fileType: FILE_FORMATS.XLSX,
          productIds: this.indexes,
        }
        if (this.directRetailerId) {
          payload.retailerId = this.directRetailerId
        }
        const params = {
          isOrderSheetDownload: true,
        }
        const res = await this.$store.dispatch(DOWNLOAD_COLLECTION, {
          payload,
          params,
        })
        apiToastSuccess(res.data.message)
        this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
        this.unSelectAll()
      } catch (err) {
        apiToastWarning(err)
      }
    },
    forceToggleSelect(forceSelect) {
      this.$emit('force-toggle-select', forceSelect)
      this.handleRemoveWishlistButton()
    },
  },
}
</script>
